<template>
  <ViewCard v-if="result && view === 'card'" :data="result" :is-grid="isGgrid"></ViewCard>
  <ViewList v-else-if="result && view === 'list'" :data="result"></ViewList>
  <ViewTable v-else-if="result && view === 'table'" :data="result"></ViewTable>
  <ViewCsv v-else-if="result && view === 'csv'" :data="result"></ViewCsv>
</template>

<script setup>
import { UziQuery } from "@/apollo/queries/UziQuery.gql";
import { queryFixer } from "@/lib/tools";
const emit = defineEmits(["nodata"]);
const page = inject("page");
// const runtimeConfig = useRuntimeConfig();
const props = defineProps({
  query: { type: Object, default: () => {} },
  format: { type: Object, default: () => {} },
  fields: { type: Array, default: () => [] },
  review: { type: Boolean, default: false },
  reviewOnly: { type: Boolean, default: false },
  preloaded: { type: Array, default: () => null },
  hydratedEmpty: { type: Boolean, default: false },
  truncateTitles: { type: Boolean, default: false },
});
const { fields, preloaded } = toRefs(props);
const isGgrid = props.fields.length === 1 && ["Logo", "Logo with Download"].includes(fields[0]);
const view = props?.format?.disptype || "list";
const variables = computed(() => {
  return {
    query: queryFixer(props.query, page), // props?.query, //
    fields: fields.value,
    hostname: unref(page).hostname, // || runtimeConfig.public.hostname,
    review: props?.review ? !!props.review : false,
    reviewOnly: props?.reviewOnly ? !!props.reviewOnly : false,
    truncateTitles: props.truncateTitles,
  };
});
if (props.hydratedEmpty) emit("nodata");

const result = ref(preloaded.value);
if (!props.hydratedEmpty && !preloaded.value) {
  const { data } = await useAsyncQuery(UziQuery, variables.value);
  if (!unref(data)?.UziQuery?.length) emit("nodata");
  else result.value = unref(data).UziQuery; // resultFixer(data, variables);
}
</script>
